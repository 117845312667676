import React from "react"
import clsx from "clsx"
// import { _localizeText } from '../../core/utils';

const ScrollToTop = () => {
  // const [hide, setHide] = useState()

  // useEffect(() => {
  //   _onScroll()
  //   window.addEventListener("scroll", _onScroll)

  //   return () => window.removeEventListener("scroll", _onScroll)
  // }, [])

  // const _onScroll = () => {
  //   // console.log(window.pageYOffset)
  //   if (window.pageYOffset < window.innerHeight) {
  //     setHide(true)
  //   } else {
  //     setHide(false)
  //   }
  // }

  const _onClick = () => {
    // window.scrollTo(0, 0)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div className="w-full text-center mb-sm- pb-[60px] md:pb-[50px]">
      <button
        className={clsx(
          "transition-all duration-300 text-sm-alt z-50 text-tertiary cursor-none"
        )}
        onClick={() => _onClick()}
        aria-label="scroll to top"
      >
        <div className="label underline pointer-events-none">Back to top</div>
      </button>
    </div>
  )
}

export default ScrollToTop
