import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SanityTexte from "./SanityTexte"
import Logo from "./Logo"

const query = graphql`
  query FooterQ {
    sanityFooter {
      signature: _rawSignature(resolveReferences: { maxDepth: 10 })
      contacts: _rawContacts(resolveReferences: { maxDepth: 10 })
      socialLinks {
        link
        label
        icon {
          asset {
            url
          }
        }
      }
    }
  }
`

const Footer = () => {
  const { sanityFooter } = useStaticQuery(query)
  const { signature, contacts, socialLinks } = sanityFooter
  return (
    <footer className="" id="contact">
      <div className="inner p-md py-[60px] md:p-lg md:pt-5 md:pb-xxl px-md md:px-7 bg-primary text-secondary z-10">
        {/* <div className="px-lg md:px-0">
          <div className="row center-xs- start-md">
            <div className="col-md-3 col-xs-1"></div>
            <div className="col-md-6 col-xs-11">
              <div className="signature font-sans text-sm text-left">
                <SanityTexte input={sanityFooter.signature} />
              </div>
            </div>
          </div>
        </div> */}
        <div className="row center-xs- start-md">
          <div className="col-md-3 col-xs-1"></div>
          <div className="col-md-6 col-xs-11">
            <div className="signature font-sans text-sm text-left">
              <SanityTexte input={signature} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2">
            <h3 className="text-xs font-sans -translate-x-full- text-right py-3 uppercase">
              contact
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-xs-1"></div>
          <div className="col-md-6 col-xs-11">
            <div className="contacts mb-lg pb-sm text-md md:text-sm-">
              <SanityTexte input={contacts} />
            </div>
            <ul className="socials flex">
              {socialLinks.map((li, i) => (
                <li key={i}>
                  <a
                    href={li.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={li.label}
                    className="pr-sm block"
                  >
                    <img src={li.icon.asset.url} alt="" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-3"></div>
          <hr />
        </div>
      </div>

      <div className="site-name flex justify-center bg-primary mix-blend-difference py-sm">
        <Logo />
      </div>
    </footer>
  )
}

export default Footer
