import React, { useEffect, createContext } from "react"
import Header from "./Header"
import Footer from "./Footer"
// import Cursor from "./ui/Cursor"
import ScrollToTop from "./ui/ScrollToTop"
import { useScroll } from "../hooks/useScroll"

const WrapperContext = createContext({})

const Layout = ({ children, pageContext, location }) => {
  const settings = {}
  const { isBelowViewPort } = useScroll()

  useEffect(() => {
    _format()
    window.addEventListener("resize", _format)
    return () => {
      window.removeEventListener("resize", _format)
    }
  }, [])

  const _format = () => {
    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    )

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect()

    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    )

    const footerBounding = document
      .querySelector("footer")
      .getBoundingClientRect()

    document.documentElement.style.setProperty(
      "--footer-height",
      footerBounding.height + "px"
    )
  }

  useEffect(() => {
    if (isBelowViewPort) {
      document.body.classList.add("is-below-vp")
    } else {
      document.body.classList.remove("is-below-vp")
    }
  }, [isBelowViewPort])

  return (
    <WrapperContext.Provider value={{ settings, location }}>
      <div id="page">
        <Header />
        <main>
          <div>{children}</div>
          <ScrollToTop />
        </main>
        <Footer />
      </div>
      {/* <Cursor size={20} color="white" /> */}
    </WrapperContext.Provider>
  )
}

export { WrapperContext, Layout }
