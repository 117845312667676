import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Logo from "./Logo"
import { useScroll } from "../hooks/useScroll"
// import Burger from "./ui/Burger"
import clsx from "clsx"

const query = graphql`
  query HeaderQ {
    sanityHeader {
      siteName
    }
  }
`

const Header = () => {
  const { sanityHeader } = useStaticQuery(query)
  // console.log(sanityHeader)
  const { isBelowViewPort } = useScroll()
  return (
    <header
      className={clsx(
        "p-md md:p-lg fixed z-50 w-full mix-blend-difference- pointer-events-none flex justify-center",
        isBelowViewPort ? "logo-black" : "logo-white"
      )}
    >
      <div className="site-name ">
        <Link
          to="/"
          className="pointer-events-all"
          title={sanityHeader.siteName}
        >
          {/* {sanityHeader.siteName} */}
          <Logo />
        </Link>
      </div>
    </header>
  )
}

export default Header
