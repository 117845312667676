import React from "react"
import LogoSvg from "../images/clothes_agency_logo.inline.svg"

const Logo = () => {
  return (
    <div className="logo">
      <LogoSvg />
    </div>
  )
}

export default Logo
