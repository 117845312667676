import React from "react"
import PortableText from "@sanity/block-content-to-react"

const SanityTexte = ({ input }) => {
  // console.log(input);
  const serializers = {
    types: {
      block(props) {
        // console.log(props.node.style)
        switch (props.node.style) {
          case "h2":
            return <h2>{props.children}</h2>
          case "text-lg":
            return <p className="text-lg">{props.children}</p>
          case "text-center":
            return <p className="text-center">{props.children}</p>
          default:
            return <p>{props.children}</p>
        }
      },
    },

    marks: {
      sup: ({ children }) => <sup>{children}</sup>,
      "text-center": ({ children }) => (
        <span className="text-center">{children}</span>
      ),
      link: ({ mark, children }) => {
        const { blank, href } = mark
        return blank ? (
          <a href={href} target="_blank" rel="noopener, noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        )
      },
    },
  }

  return (
    <div className="texte">
      <PortableText blocks={input} serializers={serializers} />
    </div>
  )
}

export default SanityTexte
